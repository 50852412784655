import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'PINE',
    lpAddresses: {
      369: '0xE846884430D527168B4EAaC80Af9268515D2f0CC',
    },
    token: tokens.pine,
    quoteToken: tokens.wpls,
    version: '1',
  },
  {
    pid: 2,
    lpSymbol: 'PINE-pDAI LP',
    lpAddresses: {
      369: '0xa1cE806C501b08072bdfe48d50C3d84b3C5a4fc3',
    },
    token: tokens.dai,
    quoteToken: tokens.pine,
    version: '2',
  },
  {
    pid: 1,
    lpSymbol: 'PINE-ATROPA LP',
    lpAddresses: {
      369: '0x0E4B3d3141608Ebc730EE225666Fd97c833d553E',
    },
    token: tokens.atropa,
    quoteToken: tokens.pine,
    version: '1',
  },
  {
    pid: 3,
    lpSymbol: 'PINE-pWBTC LP',
    lpAddresses: {
      369: '0x310c77c20B5b7872a20E7e29079B7a62016d52ba',
    },
    token: tokens.wbtc,
    quoteToken: tokens.pine,
    version: '2',
  },
  {
    pid: 4,
    lpSymbol: 'PINE-WPLS LP',
    lpAddresses: {
      369: '0xa97a6fa76a8d27d43bc94d050fd92372f269eac0',
    },
    token: tokens.wpls,
    quoteToken: tokens.pine,
    version: '2',
  },
  {
    pid: 5,
    lpSymbol: 'PINE-PLSX',
    lpAddresses: {
      369: '0x4B798587Bf68b5b5DC4a5a63A7567DA825235d29',
    },
    token: tokens.plsx,
    quoteToken: tokens.pine,
    version: '2',
  },
  {
    pid: 6,
    lpSymbol: 'PINE-pAAVE',
    lpAddresses: {
      369: '0x494a8878F614207736a0b2dBF3cB6a4Ee3dA8eba',
    },
    token: tokens.paave,
    quoteToken: tokens.pine,
    version: '2',
  },
  {
    pid: 7,
    lpSymbol: 'PINE-pLINK',
    lpAddresses: {
      369: '0x0466f1E0Ce98c4F515c5ee9b0898064b2fEf8cE0',
    },
    token: tokens.plink,
    quoteToken: tokens.pine,
    version: '2',
  },
  {
    pid: 8,
    lpSymbol: 'PINE-pMKR',
    lpAddresses: {
      369: '0x44447b77daA07fb6eFde23269eb9e28101348Fba',
    },
    token: tokens.pmkr,
    quoteToken: tokens.pine,
    version: '2',
  },
  {
    pid: 9,
    lpSymbol: 'PINE-eHEX',
    lpAddresses: {
      369: '0x145FA4465e2ddC20cdE2a3ca6071Ecbcb1144624',
    },
    token: tokens.ehex,
    quoteToken: tokens.pine,
    version: '2',
  },
  {
    pid: 10,
    lpSymbol: 'PINE-pUSDC',
    lpAddresses: {
      369: '0xaeedbefaf43165556f595ada0c7bf181e535bd0d',
    },
    token: tokens.pusdc,
    quoteToken: tokens.pine,
    version: '2',
  },
  {
    pid: 11,
    lpSymbol: 'PINE-pUSDT',
    lpAddresses: {
      369: '0x57ae75975ebaf4c937ff21f35cb5426ff4a1661a',
    },
    token: tokens.pusdt,
    quoteToken: tokens.pine,
    version: '2',
  },
  {
    pid: 12,
    lpSymbol: 'PINE-pWETH',
    lpAddresses: {
      369: '0xAd5Ae07a7BC821986cD34c0554b20166b7Da370C',
    },
    token: tokens.pweth,
    quoteToken: tokens.pine,
    version: '2',
  },
  {
    pid: 13,
    lpSymbol: 'PINE-BEAR',
    lpAddresses: {
      369: '0x7549c2595fe6309987e079200bc5a542bd41e7da',
    },
    token: tokens.bear,
    quoteToken: tokens.pine,
    version: '2',
  },
    {
    pid: 14,
    lpSymbol: 'PINE-dOWN',
    lpAddresses: {
      369: '0xa73288548ec3349d73a2502983a2aabc25ac549c',
    },
    token: tokens.down,
    quoteToken: tokens.pine,
    version: '2',
  },
  {
    pid: 15,
    lpSymbol: 'PINE-TSFi',
    lpAddresses: {
      369: '0x4607e21e978c675e8242cb00c12dde5d326b8c23',
    },
    token: tokens.tsfi,
    quoteToken: tokens.pine,
    version: '2',
  },
    {
    pid: 16,
    lpSymbol: 'PINE-STABLE-LP',
    lpAddresses: {
      369: '0x373da0f7fcd8e5aea89d34540b8f3da16b27fa34',
    },
    token: tokens.stable,
    quoteToken: tokens.pine,
    version: '2',
  },
    {
    pid: 17,
    lpSymbol: 'PINE-pBAL',
    lpAddresses: {
      369: '0xa51e20880876d56459f49ad3250b1b4da2371be9',
    },
    token: tokens.pbal,
    quoteToken: tokens.pine,
    version: '2',
  },
      {
    pid: 18,
    lpSymbol: 'PINE-pHEX',
    lpAddresses: {
      369: '0x4e848c2d48ee1e25b4d69ebff566377b5ad3e035',
    },
    token: tokens.phex,
    quoteToken: tokens.pine,
    version: '2',
  },
    {
    pid: 19,
    lpSymbol: 'PINE-pCRV',
    lpAddresses: {
      369: '0x3ee183908c0a9ad3093d354159504b887c3acd9a',
    },
    token: tokens.pcrv,
    quoteToken: tokens.pine,
    version: '2',
  }
]

export default farms
